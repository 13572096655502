/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialNewsState } from '../state/news.state';
import { fetchBlogs, fetchImages } from 'app/views/app/news/news.service';

export const newsSlice = createSlice({
  name: 'news',
  initialState: initialNewsState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch All Blogs */
    builder.addCase(fetchBlogs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBlogs.fulfilled, (state, action) => {
      state.loading = false;
      state.blogs = action.payload;
      state.error = null;
    });
    builder.addCase(fetchBlogs.rejected, (state, action) => {
      state.loading = false;
      state.blogs = [];
      state.error = action.error.message;
    });
    /* Fetch News Images */
    builder.addCase(fetchImages.pending, (state, action) => {
      state.images.loading = true;
    });
    builder.addCase(fetchImages.fulfilled, (state, action) => {
      state.images.loading = false;
      state.images.list = action.payload;
      state.images.error = null;
    });
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { actions: newsActions, reducer: newsReducer } = newsSlice;
