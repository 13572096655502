import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { classList } from '@utils';
import DropDownMenuItem from './DropDownMenuItem';

class DropDownMenu extends Component<{ closeSecSidenav: any; menu: any }> {
  state = {
    open: false,
  };

  onItemClick = (e: any) => {
    e.preventDefault();
    this.setState({ open: !this.state.open });
  };

  renderLevels = (items: any) =>
    items.map((item: any, i: number) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.sub)}
          </DropDownMenuItem>
        );
      } else {
        return (
          <li
            key={i}
            className={classList({
              'nav-item': true,
              open: this.state.open,
            })}
            onClick={this.props.closeSecSidenav}
          >
            <NavLink activeClassName="selected" exact to={item.path}>
              <i className={`nav-icon ${item.icon}`}></i>
              <span className="item-name">{item.name}</span>
            </NavLink>
          </li>
        );
      }
    });

  render() {
    return <ul className="childNav">{this.renderLevels(this.props.menu)}</ul>;
  }
}

export default DropDownMenu;
