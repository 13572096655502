/* libraries */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialSearchState } from '../state/search.state';
import { fetchSearchResults } from 'app/search/search.service';

/* models */
import { ISearchResult } from '@dex/models/search-result.model';

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialSearchState,
  reducers: {},
  extraReducers: (builder) => {
    /* Fetch Search Results */
    builder.addCase(fetchSearchResults.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchSearchResults.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list = action.payload.data as ISearchResult[];
      state.error = null;
    });
    builder.addCase(fetchSearchResults.rejected, (state, action) => {
      state.loading = false;
      state.list = [];
      state.error = action.error.message;
    });
  },
});

export const { actions: searchActions, reducer: searchReducer } = searchSlice;
