import history from '@history';
import jwtAuthService from '../../services/jwtAuthService';
import { Dispatch } from '@reduxjs/toolkit';

export const SET_USER_DATA = 'USER_SET_DATA';
export const REMOVE_USER_DATA = 'USER_REMOVE_DATA';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export function setUserData(user: any) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      data: user,
    });
  };
}

export function logoutUser() {
  return (dispatch: Dispatch) => {
    jwtAuthService.logout();

    history.push({
      pathname: '/session/signin',
    });

    dispatch({
      type: USER_LOGGED_OUT,
    });
  };
}
