import { IDivision } from './division.model';

export interface ISearchResult {
  id: string;
  name: string;
  brand: IBrand;
  divisions: IDivision[];
  type: PersonaType;
  status: PersonaStatus;
}

export interface IBrand {
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  logo: IBrandLogo;
}

export interface IBrandLogo {
  full: string;
  mobile: string;
}

export enum PersonaType {
  LEAGUE = 'League',
  UNIVERSITY = 'University',
  TOURNAMENT = 'Tournament',
  ORGANIZATION = 'Organization',
  TEAM = 'Team',
  PLAYER = 'Player',
  COACH = 'Coach',
  CASTER = 'Caster',
  OTHER = 'Other',
}

export enum PersonaStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  LOOKING_FOR_TEAM = 'Looking for Team',
  RECRUITING = 'Recruiting',
  INVITE_ONLY = 'Invite Only',
}
