import Mock from '../mock';
import { ILeague, LeagueCategory, LeagueStatus } from '@dex/models/league.model';

const LeagueDB: { list: ILeague[] } = {
  list: [
    {
      id: '34567890',
      name: 'Localhost - Denver Chapter',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },

        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '123e4567-e89b-12d3-a456-426614174000',
          name: 'Rocket League',
          logoUrl: '/assets/images/logos/games/logo_rocket-league.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.LEAGUE,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_localhost-denver-chapter.png',
          mobile: '/assets/images/logos/logo_localhost-denver-chapter.png',
        },
      },
    },
    {
      id: '23456789',
      name: 'Titan Esports',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.LEAGUE,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_titan-esports.png',
          mobile: '/assets/images/logos/logo_titan-esports.png',
        },
      },
    },
    {
      id: '12345678',
      name: 'Pandamonium League',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.LEAGUE,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_panda_gaming.png',
          mobile: '/assets/images/logos/logo_panda_gaming.png',
        },
      },
    },
    {
      id: '45678901',
      name: 'Mizzou Esports',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.UNIVERSITY,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_mizzou-esports.png',
          mobile: '/assets/images/logos/logo_mizzou-esports.png',
        },
      },
    },
    {
      id: '56789012',
      name: 'LSU Esports',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.UNIVERSITY,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_lsu-esports.png',
          mobile: '/assets/images/logos/logo_lsu-esports.png',
        },
      },
    },
    {
      id: '67890123',
      name: 'CSU Esports',
      divisions: [
        {
          id: 'd44ed184-5ffc-4fd6-b6fc-51591343f5d7',
          name: 'Apex Legends',
          logoUrl: '/assets/images/logos/games/logo_apex-legends.png',
          sub: [
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
          ],
        },
        {
          id: 'f91aea46-12c4-4652-9939-cef40af795b4',
          name: 'Call of Duty',
          logoUrl: '/assets/images/logos/games/logo_call-of-duty.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '4d2cba2a-954c-4714-8044-396a77c5874a',
          name: 'Counter-Strike: Global Offensive',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-global-offensive.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '2040158a-efca-4244-90bd-cc855097785f',
          name: 'Counter-Strike 2',
          logoUrl: '/assets/images/logos/games/logo_counter-strike-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'b9c2feb0-09fc-4875-91eb-5e316c66fe80',
          name: 'Dota 2',
          logoUrl: '/assets/images/logos/games/logo_dota-2.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'e95ed7d7-faef-4adc-b94e-46ed2f2e268f',
          name: 'FIFA 23',
          logoUrl: '/assets/images/logos/games/logo_fifa-23.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '3d91ac69-2f04-4391-b17c-a696e970ba74',
          name: 'Fortnite',
          logoUrl: '/assets/images/logos/games/logo_fortnite.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '017c9baf-cbdd-4a62-9a0e-40210b7557ea',
          name: 'Hearthstone',
          logoUrl: '/assets/images/logos/games/logo_hearthstone.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '6b197661-cc9f-41c0-9403-30e75d327d43',
          name: 'League of Legends',
          logoUrl: '/assets/images/logos/games/logo_league_of_legends.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: 'ff4ed370-5a0c-4d21-ad71-a5aea33df9d8',
          name: 'League of Legends: Soul Fighter',
          logoUrl: '/assets/images/logos/games/logo_league-of-legends-soul-fighter.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '9e9fe6d3-e9d0-45ab-8cf8-b37465dfe3c0',
          name: 'Overwatch',
          logoUrl: '/assets/images/logos/games/logo_overwatch.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-4958-b82b-e1fc0776ba4f',
          name: 'PUBG',
          logoUrl: '/assets/images/logos/games/logo_pubg.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
        {
          id: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
          name: 'Valorant',
          logoUrl: '/assets/images/logos/games/logo_valorant.png',
          sub: [
            {
              id: '6d1f5128-299e-4eca-b092-b04edd922412',
              name: 'Gold',
              logoUrl: '/assets/images/logos/games/logo_gold-elo.png',
            },
            {
              id: '179d25a5-15e9-4846-9a5a-955a01186687',
              name: 'Platinum',
              logoUrl: '/assets/images/logos/games/logo_platinum-elo.png',
            },
            {
              id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
              name: 'Diamond+',
              logoUrl: '/assets/images/logos/games/logo_diamond-elo.png',
            },
          ],
        },
      ],
      category: LeagueCategory.UNIVERSITY,
      status: LeagueStatus.RECRUITING,
      brand: {
        primaryColor: '',
        secondaryColor: '',
        tertiaryColor: '',
        logo: {
          full: '/assets/images/logos/logo_csu-esports.png',
          mobile: '/assets/images/logos/logo_csu-esports.png',
        },
      },
    },
  ],
};

/* Gets league by name */
/* '/api/league?name=:name' */
Mock.onGet(/\/api\/league\?name=.+/).reply((config: any) => {
  const response = LeagueDB.list.find((league: ILeague) => {
    return league.name === config.params.name;
  });
  return [200, response];
});

/* Gets league by id */
/* '/api/league/{leagueId}' */
Mock.onGet(/\/api\/league\/(.+)/).reply((config: any) => {
  const leagueId = config.url.match(/\/api\/league\/(.+)/)[1];
  if (leagueId) {
    const response = LeagueDB.list.find((league: ILeague) => {
      return league.id === leagueId;
    });
    if (response) return [200, response];
  }
  return [404, null];
});

/* Gets all Leagues */
/* '/api/league/' */
Mock.onGet('/api/league').reply((config: any) => {
  const response = LeagueDB.list.sort((a: ILeague, b: ILeague) => {
    return a.name.localeCompare(b.name);
  });
  return [200, response];
});
