import React, { Component } from 'react';
import Highlight from 'react-highlight.js';
import ClipboardJS from 'clipboard';

class CodeViewer extends Component<{ children: any }> {
  constructor(prop: any) {
    /* @ts-ignore */
    super(props);
    /* @ts-ignore */
    this.elem = React.createRef();
  }
  tstate = {};

  componentDidMount() {
    /* @ts-ignore */
    const pre = this.elem.current.refs.code.parentElement;

    // add/remove class to pre tag
    pre.classList.add('collapsed');
    pre.addEventListener('click', (e: any) => {
      e.target.classList.remove('collapsed');
    });

    // Add copy button
    var copyButton = document.createElement('BUTTON');
    var copyButtonText = document.createTextNode('Copy');
    copyButton.appendChild(copyButtonText);
    copyButton.classList.add('btn', 'btn-sm', 'btn-primary', 'btn-copy');
    pre.appendChild(copyButton);

    // Clipboard
    new ClipboardJS(copyButton, {
      text: (trigger) => {
        /* @ts-ignore */
        return this.elem.current.props.children;
      },
    });
  }

  render() {
    return (
      <div className="code-viewer">
        {/* @ts-ignore */}
        <Highlight ref={this.elem} {...this.props}>
          {this.props.children}
        </Highlight>
      </div>
    );
  }
}

export default CodeViewer;
