import { IDivision } from '@dex/models/division.model';

export interface IDivisionState {
  selectedDivision: any;
  selectedSubDivision?: any;
  divisions: IDivision[];
  error: any;
  loading: boolean;
}

export const initialDivisionState: IDivisionState = {
  selectedDivision: { id: '', name: 'All Games' },
  selectedSubDivision: { id: '1', name: 'All Subdivisions' },
  divisions: [],
  error: null,
  loading: false,
};
