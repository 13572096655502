/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialUserState } from '../state/user.state';

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
export const { setUser } = userActions;
