import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ISearchResult } from '@dex/models/search-result.model';

export const fetchSearchResults = createAsyncThunk(
  'search/fetchSearchResults',
  async ({
    searchTerm,
    pageNumber = 1,
    pageSize = 5,
  }: {
    searchTerm: string;
    pageNumber?: number;
    pageSize?: number;
  }) => {
    const response = await axios.get(`/api/search/${searchTerm}`);

    if (response.status !== 200) {
      throw new Error('Error fetching search results');
    }
    return response.data as ISearchResult[];
  }
);

const useFetchSearchResults = (searchTerm: string = '') => {
  const dispatch = useDispatch<AppDispatch>();
  const searchResults = useSelector((state: RootState) => state.search.list);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchSearchResults({ searchTerm }));
  }, [dispatch, searchTerm]);

  return { searchResults, loading, error };
};

export { useFetchSearchResults };
