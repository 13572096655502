import { IDivision } from './division.model';
import { IBrand } from './search-result.model';

export interface ILeague {
  id: string;
  name: string;
  divisions: IDivision[];
  category: LeagueCategory;
  status: LeagueStatus;
  brand: IBrand;
}

export enum LeagueCategory {
  LEAGUE = 'League',
  UNIVERSITY = 'University',
  TOURNAMENT = 'Tournament',
  ORGANIZATION = 'Organization',
  TEAM = 'Team',
  PLAYER = 'Player',
  COACH = 'Coach',
  CASTER = 'Caster',
  OTHER = 'Other',
}

export enum LeagueStatus {
  INACTIVE = 'Inactive',
  RECRUITING = 'Recruiting',
  INVITE_ONLY = 'Invite Only',
}
