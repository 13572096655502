/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialUpcomingEventsState } from '../state/upcoming-events.state';
import { fetchUpcomingEvents } from '@dex/components/upcoming-events/upcoming-events.service';

export const upcomingEventsSlice = createSlice({
  name: 'upcomingEvents',
  initialState: initialUpcomingEventsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpcomingEvents.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchUpcomingEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.events = action.payload;
      state.error = null;
    });
    builder.addCase(fetchUpcomingEvents.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { actions: upcomingEventsActions, reducer: upcomingEventsReducer } =
  upcomingEventsSlice;
