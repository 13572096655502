import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { RouteConfig } from 'react-router-config';

const Dashboard1 = lazy(() => import('./dashboard1/Dashboard1'));

// const Dashboard2 = lazy(() => import('./dashboard2/Dashboard2'));

const dashboardRoutes: RouteConfig[] = [
  {
    path: '/dashboard/v1',
    component: Dashboard1,
    auth: authRoles.admin,
  },
  // {
  //   path: '/dashboard/v2',
  //   component: Dashboard2,
  //   auth: authRoles.admin,
  // },
  {
    path: '/dashboard/:id',
    component: Dashboard1,
  },
];

export default dashboardRoutes;
