/* libraries */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialLayoutState } from '../state/layout.state';
import { DexLayoutSettings } from 'app/DexLayout/settings';
import { IBrand } from '@dex/models/search-result.model';

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: initialLayoutState,
  reducers: {
    setLayoutSettings: (state, action) => {
      state.settings = action.payload;
    },
    setDefaultSettings: (state) => {
      state.defaultSettings = DexLayoutSettings;
    },
    setLayoutBrand: (state, action: PayloadAction<IBrand>) => {
      state.settings.brand = action.payload;
    },
  },
});

export const { actions: layoutActions, reducer: LayoutReducer } = layoutSlice;
export const { setLayoutSettings, setDefaultSettings, setLayoutBrand } = layoutActions;
