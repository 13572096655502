import React from 'react';

import './loading.scss';

const Loading = () => {
  return (
    <div className="loading">
      <div className="spinner spinner-bubble spinner-bubble-primary"></div>
    </div>
  );
};

export default Loading;
