import { ICarouselImage } from '@dex/models/carousel-image.model';
import { IBlog } from '@dex/models/blog.model';

export interface INewsState {
  blogs?: IBlog[];
  error: any;
  loading: boolean;
  images: {
    list: ICarouselImage[];
    loading: boolean;
    error: any;
  };
}

export const initialNewsState: INewsState = {
  blogs: [],
  error: null,
  loading: false,
  images: {
    list: [],
    loading: false,
    error: null,
  },
};
