/* libraries */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

/* routes */
import dashboardRoutes from './views/dashboard/dashboardRoutes';
import sessionsRoutes from './views/sessions/sessions.routes';
import AuthGuard from './auth/AuthGuard-og';
import homeRoutes from './views/app/home/homeRoutes';
import { leagueAdminRoutes } from './views/app/league/administration/league-admin.routes';

/* misc */
import { getNavigation, NavigationType } from './navigations';
import { RouteConfig } from 'react-router-config';

const leagueComponent = lazy(() =>
  import('./views/app/league/league.component').then((module) => ({
    default: module.League,
  }))
);

const redirectRoutes: RouteConfig[] = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
  },
];

const errorRoutes: RouteConfig[] = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const lRoutes: RouteConfig[] = [
  {
    path: '/league/:leagueId',
    component: AuthGuard,
    routes: [
      {
        path: '/league/:leagueId',
        component: leagueComponent,
      },
    ],
    settings: {
      eventsbar: { show: true },
      divisionsbar: { show: true },
      searchbar: { show: true },
      // TODO we need to build this dynamically based on league id
      navigation: getNavigation(NavigationType.LEAGUE),
      brand: {
        logo: {
          full: '/assets/images/logos/logo_panda_gaming.png',
          mobile: '/assets/images/logos/logo_panda_gaming.png',
        },
      },
    },
  },
];

const laRoutes: RouteConfig[] = [
  {
    path: '/admin/league/',
    component: AuthGuard,
    routes: [...leagueAdminRoutes],
    settings: {
      eventsbar: { show: false },
      divisionsbar: { show: false },
      searchbar: { show: true },
      // TODO we need to build this dynamically based on league id
      navigation: getNavigation(NavigationType.LEAGUE_ADMIN),
      brand: {
        logo: {
          full: '/assets/images/logos/logo_panda_gaming.png',
          mobile: '/assets/images/logos/logo_panda_gaming.png',
        },
      },
    },
  },
];

const rootRoutes: RouteConfig[] = [
  {
    path: '/',
    component: AuthGuard,
    settings: {
      searchbar: { show: false },
      eventsbar: { show: false },
      divisionsbar: { show: false },
      brand: {
        logo: {
          full: '/assets/images/logos/logo_dex.png',
          mobile: '/assets/images/logos/logo_dex.png',
        },
      },
    },
    routes: [
      ...homeRoutes,
      ...dashboardRoutes,
      ...redirectRoutes,

      ...errorRoutes, // keep last in list
    ],
  },
];

const routes: RouteConfig[] = [...sessionsRoutes, ...lRoutes, ...laRoutes, ...rootRoutes];

export default routes;
