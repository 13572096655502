import { DexLayoutSettings } from 'app/DexLayout/settings';

export const initialLayoutState = {
  settings: {
    ...DexLayoutSettings,
  },
  defaultSettings: {
    ...DexLayoutSettings,
  },
};
