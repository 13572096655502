import { lazy } from 'react';
import { RouteConfig } from 'react-router-config';

const Error404 = lazy(() => import('./Error'));

const sessionsRoutes: RouteConfig[] = [
  {
    path: '/session/404',
    component: Error404,
  },
];

export default sessionsRoutes;
