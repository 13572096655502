import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { ILeague } from '@dex/models/league.model';

export const fetchLeagues = createAsyncThunk(
  'division/fetchLeagues',
  async ({ pageNumber, pageSize }: { pageNumber?: number; pageSize?: number }) => {
    const response = await axios.get('/api/league');

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }
    return response.data as ILeague[];
  }
);

export const fetchLeagueByName = createAsyncThunk(
  'division/fetchLeagueByName',
  async (leagueName: string) => {
    const response = await axios.get(`/api/league?name=${leagueName}`);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }
    return response.data as ILeague;
  }
);

export const fetchLeagueById = createAsyncThunk(
  'division/fetchLeagueById',
  async (leagueId: string) => {
    const response = await axios.get(`/api/league/${leagueId}`);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }
    return response.data as ILeague;
  }
);

const useFetchLeagues = (pageNumber?: number, pageSize?: number) => {
  const dispatch = useDispatch<AppDispatch>();
  const leagues = useSelector((state: RootState) => state.league.list);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagues({ pageNumber, pageSize }));
  }, [dispatch, pageNumber, pageSize]);

  return { leagues, loading, error };
};

const useFetchLeagueByName = (leagueName: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const league = useSelector((state: RootState) => state.league.selectedLeague);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagueByName(leagueName));
  }, [dispatch, leagueName]);

  return { league, loading, error };
};

const useFetchLeagueById = (leagueId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const league = useSelector((state: RootState) => state.league.selectedLeague);
  const loading = useSelector((state: RootState) => state.league.loading);
  const error = useSelector((state: RootState) => state.league.error);

  useEffect(() => {
    dispatch(fetchLeagueById(leagueId));
  }, [dispatch, leagueId]);

  return { league, loading, error };
};

export { useFetchLeagues, useFetchLeagueByName, useFetchLeagueById };
