import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AppContext from 'app/appContext';
import { setLayoutSettings, setDefaultSettings } from 'app/redux/slices/layout.slice';

import { DexLayouts } from '.';
import { matchRoutes } from 'react-router-config';
import { isEqual } from 'lodash';
// import Customizer from "./SharedComponents/Customizer";

interface IDexLayoutProps {
  activeLayout: any;
  route: any;
  settings: any;
  defaultSettings: any;
  setLayoutSettings: any;
  setDefaultSettings: any;
  location: any;
}
class DexLayout extends Component<IDexLayoutProps> {
  state = {};

  constructor(props: any, context: any) {
    super(props);
    /* @ts-ignore Property does not exist */
    this.appContext = context;
    this.updateSettingsFromRouter();

    // Set settings from query (Only for demo purpose)
    // this.setLayoutFromQuery();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.updateSettingsFromRouter();
    }
  }
  componentDidMount() {
    this.initAppDirection();
  }

  updateSettingsFromRouter() {
    /* @ts-ignore Property does not exist */
    const { routes } = this.appContext;
    const matched = matchRoutes(routes, this.props.location.pathname)[0];

    let { defaultSettings, settings, setLayoutSettings } = this.props;

    if (matched && matched.route.settings) {
      const updatedSettings = { ...settings, ...matched.route.settings };
      if (!isEqual(settings, updatedSettings)) {
        setLayoutSettings(updatedSettings);
      }
    } else if (!isEqual(settings, defaultSettings)) {
      console.log('routes not found');

      setLayoutSettings(defaultSettings);
    }
  }

  initAppDirection = () => {
    /* @ts-ignore Property does not exist */
    let { settings } = this.props;
    setTimeout(() => {
      document.documentElement.setAttribute('dir', settings.dir);
    });
  };

  render() {
    let { activeLayout, route } = this.props; //settings
    /* @ts-ignore Property does not exist */
    let Layout = DexLayouts[activeLayout];
    return (
      <Suspense>
        <React.Fragment>
          <Layout routes={route.routes}></Layout>
        </React.Fragment>
      </Suspense>
    );
  }
}

const mapStateToProps = (state: any) => ({
  setLayoutSettings: PropTypes.func.isRequired,
  setDefaultSettings: PropTypes.func.isRequired,
  settings: state.layout.settings,
  activeLayout: state.layout.settings.activeLayout,
  defaultSettings: state.layout.defaultSettings,
});

DexLayout.contextType = AppContext;

export default withRouter(
  /* @ts-ignore Property does not exist */
  connect(mapStateToProps, { setLayoutSettings, setDefaultSettings })(DexLayout)
);
