import { CalendarEventColor } from 'app/views/app/calendar/models/calendar-event-color.enum';
import Mock from '../mock';
import shortid from 'shortid';
const date = new Date();

const calendarEventDB = {
  events: [
    {
      id: '344jdfher3wh23',
      title: 'Match: Bamboo vs. Green Leaves @ 8:00pm ',
      start: new Date(date.getFullYear(), date.getMonth(), 1),
      end: new Date(date.getFullYear(), date.getMonth(), 1),
      color: CalendarEventColor.ORANGE,
      backgroundColor: CalendarEventColor.ORANGE,
      allDay: false,
    },
    {
      id: '344jdfher3wh245',
      title: 'Staff All-Hands',
      start: new Date(),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate() + 2),
      backgroundColor: CalendarEventColor.PURPLE,
      color: CalendarEventColor.PURPLE,
      allDay: false,
    },
  ],
};

Mock.onGet('/api/calendar/events/all').reply((config: any) => {
  return [200, calendarEventDB.events];
});

Mock.onPost('/api/calendar/events/add').reply((config: any) => {
  let { start, end, ...others } = JSON.parse(config.data);
  let event = {
    id: shortid.generate(),
    start: new Date(start),
    end: new Date(end),
    ...others,
  };
  calendarEventDB.events.push(event);
  return [200, calendarEventDB.events];
});

Mock.onPost('/api/calendar/events/update').reply((config: any) => {
  let updatedEvent = JSON.parse(config.data);
  calendarEventDB.events = calendarEventDB.events.map((event) => {
    if (event.id === updatedEvent.id) {
      return { ...updatedEvent };
    }
    return event;
  });

  return [200, calendarEventDB.events];
});

Mock.onPost('/api/calendar/events/delete').reply((config: any) => {
  let event = JSON.parse(config.data);

  // eslint-disable-next-line array-callback-return
  calendarEventDB.events.map(function (element, i) {
    if (element.id === event.id) {
      return [200, calendarEventDB.events.splice(i, 1)];
    }
  });

  return [200, calendarEventDB.events];
});
