/* libraries */
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

/* redux */
import { persistedReducer } from './reducers/root.reducer';

const middleware = [thunk];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

//clearing the store in production for now while code is unstable and in development
persistor.pause();
persistor.flush().then(() => {
  return persistor.purge();
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

// initialState,
// compose(applyMiddleware(...middlewares), composeWithDevTools())
