import { NavigationType, getNavigation } from 'app/navigations';

export const DexLayoutSettings = {
  activeLayout: 'layout1', // layout1, layout2, layout3
  dir: 'ltr', // ltr, rtl
  customizer: {
    show: false,
    open: false,
  },
  footer: {
    show: true,
  },
  brand: {
    logo: {
      full: '/assets/images/logo.png',
      mobile: '/assets/images/logo.png',
    },
    primaryColor: '',
    secondaryColor: '',
    tertiaryColor: '',
  },
  eventsbar: { show: false },
  divisionsbar: { show: false },
  searchbar: { show: false },
  leftSidebar: {
    open: true,
  },
  searchBox: {
    open: false,
  },
  navigation: getNavigation(NavigationType.LANDING),
};
