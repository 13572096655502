import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IDivision } from '@dex/models/division.model';

export const fetchDivisions = createAsyncThunk(
  'division/fetchDivisions',
  async (leagueId: string) => {
    // const url = `/api/league/${leagueId}/division`;
    const url = '/api/division';
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching divisions');
    }

    return response.data as IDivision[];
  }
);

const useFetchDivisions = (leagueId: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const divisions = useSelector((state: RootState) => state.division.divisions);
  const loading = useSelector((state: RootState) => state.division.loading);
  const error = useSelector((state: RootState) => state.division.error);

  useEffect(() => {
    dispatch(fetchDivisions(leagueId));
  }, [dispatch, leagueId]);

  return { divisions, loading, error };
};

export default useFetchDivisions;
