class LocalStorageService {
  ls = window.localStorage;

  setItem(key: string, value: string) {
    value = JSON.stringify(value);
    this.ls.setItem(key, value);
    return true;
  }

  getItem(key: string) {
    const value = this.ls.getItem(key) || '';
    try {
      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  }
}

const localStorageService = new LocalStorageService();

export default localStorageService;
