import React, { useEffect, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { setUserData } from 'app/redux/actions/UserActions';

const Auth = ({ children }: { children: any }) => {
  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      setUserData(user);
    }
  }, [isAuthenticated, user]);

  return <Fragment>{children}</Fragment>;
};

export { Auth };
