import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { RouteConfig } from 'react-router-config';

const Home = lazy(() => import('./Home'));

const homeRoutes: RouteConfig[] = [
  {
    path: '/home',
    component: Home,
    auth: authRoles.guest,
  },
];

export default homeRoutes;
