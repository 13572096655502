import Mock from './mock';

import './db/invoice.db';
import './db/calendar-events.db';
import './db/users.db';
import './db/inbox.db';
import './db/chat.db';
import './db/todos.db';
import './db/list.db';
import './db/scrum-board.db';
import './db/notification.db';
import './db/ecommerce.db';

import './db/blogs.db';
import './db/upcoming-events.db';
import './db/league.db';
import './db/divisions.db';
import './db/rules.db';
import './db/search.db';
import './db/carousel-images.db';

Mock.onAny().passThrough();
