import { User } from '@auth0/auth0-react';
export interface IUserState {
  currentUser?: User | null;
  error: any;
  loading: boolean;
}

export const initialUserState: IUserState = {
  currentUser: null,
  error: null,
  loading: false,
};
