import { IUpcomingEvent } from '@dex/models/upcoming-event.model';
import Mock from '../mock';

const start1 = new Date();
const start2 = new Date();
const start3 = new Date();
start1.setSeconds(-2032);
start2.setSeconds(-1400);
start3.setMinutes(75);

start3.toLocaleDateString('en-us', { hour: '2-digit' });

const UpcomingEventsDB: { list: IUpcomingEvent[] } = {
  list: [
    {
      title: 'Live',

      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'BB',
        name: 'Bamboo',
        logoUrl: '/assets/images/logos/logo_bamboo.png',
        kda: { kills: 30, deaths: 15, assists: 31 },
      },
      away: {
        code: 'GL',
        name: 'Green Leaves',
        logoUrl: '/assets/images/logos/logo_green_leaves.png',
        kda: { kills: 15, deaths: 30, assists: 14 },
      },
      active: true,
      startDateTime: start1,
    },
    {
      title: 'Live',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
        kda: { kills: 12, deaths: 4, assists: 15 },
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
        kda: { kills: 4, deaths: 12, assists: 3 },
      },
      active: true,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: 'a190c2f1-4a88-4808-9f58-2871a0d3234a',
        name: 'Diamond+',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: '179d25a5-15e9-4846-9a5a-955a01186687',
        name: 'Platinum',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
    {
      title: 'Upcoming',
      division: {
        id: '6b197661-cc9f-41c0-9403-30e75d327d43',
        name: 'League of Legends',
        logoUrl: `/assets/images/logos/logo_league_of_legends.png`,
        sub: [],
      },
      subdivision: {
        id: '179d25a5-15e9-4846-9a5a-955a01186687',
        name: 'Platinum',
        logoUrl: '',
      },
      home: {
        code: 'PK',
        name: 'Panda Kings',
        logoUrl: '/assets/images/logos/logo_panda_kings.png',
      },
      away: {
        code: 'LP',
        name: 'Lazy Pandas',
        logoUrl: '/assets/images/logos/logo_lazy_pandas.png',
      },
      active: false,
      startDateTime: start2,
    },
  ],
};

/* Gets upcoming events for a league by division */
/* '/api/league/{leagueId}/upcoming-events?division={divisionId}' */
Mock.onGet(/^\/api\/league\/\w+\/upcoming-events\/?divisionId=\w+$/).reply(
  (config: any) => {
    const response = UpcomingEventsDB.list.find((event: any) =>
      event.division.id !== 1 ? event.division.id === config.params.divisionId : true
    );
    return [200, response];
  }
);

/* Gets all upcoming events for a league */
/* '/api/league/{leagueId}/upcoming-events' */
Mock.onGet(/\/api\/league\/([^/]+)\/upcoming-events/).reply((config: any) => {
  const response = UpcomingEventsDB.list;
  return [200, response];
});
