import { ISearchResult } from '@dex/models/search-result.model';

export interface ISearchState {
  list?: ISearchResult[];
  error: any;
  loading: boolean;
}

export const initialSearchState: ISearchState = {
  error: null,
  loading: false,
};
