/* libs */
import Mock from '../mock';
import { ICarouselImage } from '@dex/models/carousel-image.model';
import { ITag } from '@dex/models/tag.model';

interface ICarouselImagesDB {
  list: ICarouselImage[];
  tags: ITag[];
}

const CarouselImagesDB: ICarouselImagesDB = {
  list: [
    {
      id: '1',
      src: '/assets/images/league/news/platinum-banner.png',
      alt: 'League of Legends - Vi',
      title: 'League of Legends Platinum League',
      description:
        'New bracket for League of Legends! Check out our new Platinum League for any players ranked in Platinum over the last 3 seasons.',
      leagueId: '12345678',
      divisionId: '6b197661-cc9f-41c0-9403-30e75d327d43',
    },
    {
      id: '2',
      src: '/assets/images/league/news/valorant-banner.png',
      alt: 'Valorant Banner',
      title: 'Valorant',
      description:
        'Panda Gaming League is now running tournamens for Valorant! Register Now!',
      leagueId: '12345678',
      divisionId: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
    },
  ],

  // league id's 100-199
  // division id's 200-299
  // sub division id's 300-399
  tags: [
    // pandamoinum leauge
    {
      id: '101',
      name: 'league',
      value: '12345678',
      description: 'Pandamonum League',
    },
    // localhost
    {
      id: '102',
      name: 'league',
      value: '34567890',
      description: 'Localhost - Denver Chapter',
    },
    // titan esports
    {
      id: '103',
      name: 'league',
      value: '23456789',
      description: 'Titan Esports',
    },
    // league of legends
    {
      id: '201',
      name: 'division',
      value: '6b197661-cc9f-41c0-9403-30e75d327d43',
      description: 'League of Legends',
    },
    // valorant
    {
      id: '202',
      name: 'division',
      value: '5d3ede65-4392-1234-b82b-e1fc0776ba4f',
      description: 'Valorant',
    },
  ],
};

Mock.onGet('/api/news/images').reply((config: any) => {
  // Extract query string parameters
  const leagueId: string | null = config.params?.leagueId ?? null;
  const divisionId: string | null = config.params?.divisionId ?? null;
  const subDivisionId: string | null = config.params?.subDivisionId ?? null;

  let filteredImages: ICarouselImage[];

  // Handle the different conditions based on the provided parameters
  if (leagueId === null) {
    filteredImages = CarouselImagesDB.list; // Return the entire list if all are null
  } else if (divisionId === null) {
    filteredImages = CarouselImagesDB.list.filter((image) => image.leagueId === leagueId);
  } else if (subDivisionId === null) {
    filteredImages = CarouselImagesDB.list.filter(
      (image) => image.leagueId === leagueId && image.divisionId === divisionId
    );
  } else {
    filteredImages = CarouselImagesDB.list.filter(
      (image) =>
        image.leagueId === leagueId &&
        image.divisionId === divisionId &&
        image.subDivisionId === subDivisionId
    );
  }

  // Return the result with a 200 status code
  return [200, filteredImages];
});
