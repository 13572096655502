/* libraries */
import { createSlice } from '@reduxjs/toolkit';

/* redux */
import { initialLeagueState } from '../state/league.state';
import {
  fetchLeagues,
  fetchLeagueById,
  fetchLeagueByName,
} from 'app/views/app/league/league.service';

export const leagueSlice = createSlice({
  name: 'league',
  initialState: initialLeagueState,
  reducers: {
    setSelectedLeague: (state, action) => {
      state.selectedLeague = action.payload;
    },
  },
  extraReducers: (builder) => {
    /* Fetch All Leagues */
    builder.addCase(fetchLeagues.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLeagues.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
      state.error = null;
    });
    builder.addCase(fetchLeagues.rejected, (state, action) => {
      state.loading = false;
      state.selectedLeague = null;
      state.error = action.error.message;
    });

    /* Fetch League by Name */
    builder.addCase(fetchLeagueByName.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchLeagueByName.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedLeague = action.payload;
      state.error = null;
    });
    builder.addCase(fetchLeagueByName.rejected, (state, action) => {
      state.loading = false;
      state.selectedLeague = null;
      state.error = action.error.message;
    });
    /* Fetch League by Id */
    builder.addCase(fetchLeagueById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchLeagueById.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedLeague = action.payload;
      state.error = null;
    });
    builder.addCase(fetchLeagueById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { actions: leagueActions, reducer: leagueReducer } = leagueSlice;
export const { setSelectedLeague } = leagueActions;
