import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from 'app/appContext';
import DexLayout from 'app/DexLayout/DexLayout';
import { flatMap } from 'lodash';

interface IAuthGuardProps {
  route: any;
  location: any;
  history: any;
  user: any;
}
class AuthGuard extends Component<
  { route: any; location: any; history: any; user: any },
  any
> {
  constructor(props: IAuthGuardProps, context: any) {
    super(props);
    let { routes } = context;

    this.state = {
      authenticated: true,
      routes,
    };
  }

  componentDidMount() {
    this.setState({
      routes: flatMap(this.state.routes, (item) => {
        if (item.routes) {
          return [...item.routes];
        }
        return [item];
      }),
    });

    /* @ts-ignore Property does not exist on type 'Readonly<{}> */
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  componentDidUpdate() {
    if (!this.state.authenticated) {
      this.redirectRoute(this.props);
    }
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    /* @ts-ignore Property does not exist on type 'Readonly<{}> */
    return nextState.authenticated !== this.state.authenticated;
  }

  static getDerivedStateFromProps(props: IAuthGuardProps, state: any) {
    // const { location, user } = props;
    // const { pathname } = location;
    // const matched = state.routes.find(r => r.path === pathname);
    const authenticated = true;
    // matched && matched.auth && matched.auth.length
    //   ? matched.auth.includes(user.role)
    //   : true;

    return {
      authenticated,
    };
  }

  redirectRoute(props: any) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: '/session/signin',
      state: { redirectUrl: pathname },
    });
  }

  render() {
    let { route } = this.props;
    const { authenticated } = this.state;

    return authenticated ? (
      <Fragment>
        {/* @ts-ignore might be real error */}
        <DexLayout route={route}></DexLayout>
      </Fragment>
    ) : null;
  }
}

AuthGuard.contextType = AppContext;

const mapStateToProps = (state: any) => ({
  user: state.user,
});

/* @ts-ignore Property does not exist on type 'Readonly<{}> */
export default withRouter(connect(mapStateToProps)(AuthGuard));
