/* libs */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'app/redux/Store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { IUpcomingEvent } from '@dex/models/upcoming-event.model';

export const fetchUpcomingEvents = createAsyncThunk(
  'division/fetchUpcomingEvents',
  async ({
    leagueId,
    divisionId,
    subDivisionId,
  }: {
    leagueId: string;
    divisionId?: string;
    subDivisionId?: string;
  }) => {
    let url = `/api/league/${leagueId}/upcoming-events`;

    const queryParams = [];
    if (divisionId && divisionId !== '1') {
      queryParams.push(`divisionId=${divisionId}`);
    }
    if (subDivisionId) {
      queryParams.push(`subDivisionId=${subDivisionId}`);
    }

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error('Error fetching events');
    }

    return response.data as IUpcomingEvent[];
  }
);

const useFetchUpcomingEvents = (
  leagueId: string,
  divisionId?: string,
  subDivisionId?: string
) => {
  const dispatch = useDispatch<AppDispatch>();
  const upcomingEvents = useSelector((state: RootState) => state.upcomingEvents.events);
  const loading = useSelector((state: RootState) => state.upcomingEvents.loading);
  const error = useSelector((state: RootState) => state.upcomingEvents.error);

  useEffect(() => {
    dispatch(fetchUpcomingEvents({ leagueId, divisionId, subDivisionId }));
  }, [dispatch, leagueId, divisionId, subDivisionId]);

  return { upcomingEvents: upcomingEvents, loading, error };
};

export default useFetchUpcomingEvents;
